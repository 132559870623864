
.message[data-v-3528fef4] {
  border: 1px solid #bebebe;
  background: #ffffff;
  font-family: 'Trebuchet MS', 'Helvetica', sans-serif;
  cursor: default;
  position: relative;
  width: 300px;
}
.icon[data-v-3528fef4] {
  display: block;
  line-height: 0;
}
.alert[data-v-3528fef4] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 8px;
}
.alert .icon[data-v-3528fef4] {
  margin-right: 8px;
  width: 48px;
}
.alert .icon img[data-v-3528fef4] {
  width: 48px;
  height: 48px;
}
.alert .content[data-v-3528fef4] {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  line-break: anywhere;
}
.alert .text[data-v-3528fef4] {
  margin-top: 4px;
  margin-bottom: 4px;
}
.card .icon img[data-v-3528fef4] {
  width: 100%;
}
.card .title[data-v-3528fef4] {
  padding: 5px 7px;
}
.card .text[data-v-3528fef4],
.card .date[data-v-3528fef4] {
  padding: 3px 7px;
}
.card .content[data-v-3528fef4] {
  line-break: anywhere;
}
.title[data-v-3528fef4],
.title[data-v-3528fef4] p {
  font-size: 12px;
  line-height: normal;
  font-family: 'Trebuchet MS', 'Helvetica', sans-serif;
  font-weight: bold;
  margin: 0;
}
.text[data-v-3528fef4],
.text[data-v-3528fef4] p {
  font-size: 12px;
  line-height: normal;
  font-family: 'Trebuchet MS', 'Helvetica', sans-serif;
  margin: 0;
}
.date[data-v-3528fef4] {
  font-size: 11px;
  line-height: normal;
  color: #b7b7b7;
}

.bee-shopbox-plugin-wrapper[data-v-dc70810c] {
  display: grid;
  grid-template-rows: 1fr 65px;
  max-height: calc(85vh - 40px);
}
.bee-shopbox-plugin[data-v-dc70810c] {
  font-family: 'Open Sans', sans-serif;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin: 10px 0;
  padding: 10px 15px;
  overflow-y: auto;
  overflow-x: hidden;
}
.footer[data-v-dc70810c] {
  bottom: 0;
  width: calc(100% - 40px);
  padding: 0 0px 20px;
  background: white;
  z-index: 1000;
  margin: 0 auto;
}
.footer .el-divider[data-v-dc70810c] {
  margin-top: 0;
}
.title[data-v-dc70810c] {
  font-size: 20px;
  text-align: center;
}
.section-title[data-v-dc70810c] {
  font-size: 20px;
}
.flex-column[data-v-dc70810c] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.options-title[data-v-dc70810c] {
  font-size: 16px;
}
.notification[data-v-dc70810c] {
  height: 100%;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
[data-v-dc70810c] label.el-radio {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
[data-v-dc70810c] label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 5px;
}
[data-v-dc70810c] .field {
  margin: 5px 0;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 40%;
          flex: 0 0 40%;
}
[data-v-dc70810c] .el-divider--horizontal {
  margin: 14px 0;
}
[data-v-dc70810c] .el-radio__label {
  font-size: 18px;
}
[data-v-dc70810c] .el-switch__label > span {
  font-size: 16px;
}
[data-v-dc70810c] span,[data-v-dc70810c] label {
  text-transform: none !important;
}
[data-v-dc70810c] .redactor-layer {
  padding: 10px;
}
.option-label[data-v-dc70810c] {
  font-size: 14px;
}
.product-link-button-section[data-v-dc70810c] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-pack: distribute;
      justify-content: space-around;
}
.product-link-button-section .field[data-v-dc70810c] {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 90%;
          flex: 0 0 90%;
}
[data-v-dc70810c] .is-checked > .el-radio__inner,[data-v-dc70810c] .el-button--primary {
  border-color: #1d87c8;
  background: #1d87c8;
}
[data-v-dc70810c] .is-checked > .el-radio__label {
  color: #1d87c8;
}
[data-v-dc70810c] .el-select .el-input.is-focus .el-input__inner {
  border-color: #1d87c8;
}
[data-v-dc70810c] .el-input--small .el-input__inner {
  height: 32px;
  line-height: 32px;
}
[data-v-dc70810c] .color-picker-ct .el-input__inner {
  border: none !important;
  height: 34px !important;
}

html,
body {
  margin: 0;
}
.el-select-dropdown__item.selected {
  color: #1d87c8;
}

.short-divider[data-v-58d17c0e] {
  width: 50%;
  margin: 5px 0;
}
.field .el-input-number[data-v-58d17c0e] {
  width: 100%;
}
.variant[data-v-58d17c0e] {
  margin: 10px 0;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.option-label[data-v-58d17c0e] {
  font-size: 14px;
}

.short-divider[data-v-07c3d6c2] {
  width: 50%;
  margin: 5px 0;
}
.field .el-input-number[data-v-07c3d6c2] {
  width: 100%;
}
.variant[data-v-07c3d6c2] {
  margin: 10px 0;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.option-label[data-v-07c3d6c2] {
  font-size: 14px;
}
.el-switch[data-v-07c3d6c2] {
  margin-top: 10px;
}

.short-divider[data-v-035b69e1] {
  width: 50%;
  margin: 5px 0;
}
.variant[data-v-035b69e1] {
  margin: 10px 0;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.field .el-input-number[data-v-035b69e1] {
  width: 100%;
}
.option-label[data-v-035b69e1] {
  font-size: 14px;
}

.bee-shopbox-plugin-wrapper[data-v-5c58d928] {
  display: grid;
  grid-template-rows: 1fr 65px;
  max-height: calc(85vh - 40px);
}
.bee-shopbox-plugin[data-v-5c58d928] {
  font-family: 'Open Sans', sans-serif;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin: 10px 0;
  padding: 10px 15px;
  overflow-y: auto;
  overflow-x: hidden;
}
.footer[data-v-5c58d928] {
  bottom: 0;
  width: calc(100% - 40px);
  padding: 0 0px 20px;
  background: white;
  z-index: 1000;
  margin: 0 auto;
}
.footer .el-divider[data-v-5c58d928] {
  margin-top: 0;
}
.title[data-v-5c58d928] {
  font-size: 20px;
  text-align: center;
}
.section-title[data-v-5c58d928] {
  font-size: 20px;
}
.flex-column[data-v-5c58d928] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.options-title[data-v-5c58d928] {
  font-size: 16px;
}
.notification[data-v-5c58d928] {
  height: 100%;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
[data-v-5c58d928] label.el-radio {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
[data-v-5c58d928] label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 5px;
}
[data-v-5c58d928] .field {
  margin: 5px 0;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 40%;
          flex: 0 0 40%;
}
[data-v-5c58d928] .el-divider--horizontal {
  margin: 14px 0;
}
[data-v-5c58d928] .el-radio__label {
  font-size: 18px;
}
[data-v-5c58d928] .el-switch__label > span {
  font-size: 16px;
}
[data-v-5c58d928] span,[data-v-5c58d928] label {
  text-transform: none !important;
}
[data-v-5c58d928] .redactor-layer {
  padding: 10px;
}
.option-label[data-v-5c58d928] {
  font-size: 14px;
}
.product-link-button-section[data-v-5c58d928] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-pack: distribute;
      justify-content: space-around;
}
.product-link-button-section .field[data-v-5c58d928] {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 90%;
          flex: 0 0 90%;
}
[data-v-5c58d928] .is-checked > .el-radio__inner,[data-v-5c58d928] .el-button--primary {
  border-color: #1d87c8;
  background: #1d87c8;
}
[data-v-5c58d928] .is-checked > .el-radio__label {
  color: #1d87c8;
}
[data-v-5c58d928] .el-select .el-input.is-focus .el-input__inner {
  border-color: #1d87c8;
}
[data-v-5c58d928] .el-input--small .el-input__inner {
  height: 32px;
  line-height: 32px;
}
[data-v-5c58d928] .color-picker-ct .el-input__inner {
  border: none !important;
  height: 34px !important;
}
.TextStyles[data-v-5c58d928] {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 10px;
}

html,
body {
  margin: 0;
}
.el-select-dropdown__item.selected {
  color: #1d87c8;
}
.BarcodePreview {
  width: 400px;
  height: 400px;

  display: -webkit-box;

  display: -ms-flexbox;

  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
